import * as t from 'io-ts';

import { fromEnum } from '../runtime';

/**
 * Request parameters for creating a collection
 *
 * @deprecated
 */
export const CreateCollectionParamsCodec = t.intersection([
  t.type({
    /**
     * Name of the collection (required)
     */
    name: t.string,
    /**
     * Ethereum address of the ERC721 contract (required, must be an ETH address)
     */
    contract_address: t.string,
    /**
     * Owner Public Key: The public key of the owner of the contract (required)
     */
    owner_public_key: t.string,
    /**
     * The collection's project ID (required)
     */
    project_id: t.number,
  }),
  t.partial({
    /**
     * URL of the metadata for this collection
     */
    metadata_api_url: t.string,
    /**
     * Description of the collection
     */
    description: t.string,
    /**
     * URL of the icon for this collection
     */
    icon_url: t.string,
    /**
     * URL of the tile image for this collection
     */
    collection_image_url: t.string,
  }),
]);

/** @deprecated */
export type CreateCollectionParamsF = t.TypeOf<
  typeof CreateCollectionParamsCodec
>;
export type CreateCollectionParams = t.OutputOf<
  typeof CreateCollectionParamsCodec
>;

/**
 * Request parameters for updating a collection
 */
export const UpdateCollectionParamsCodec = t.partial({
  /**
   * Name of the collection
   */
  name: t.union([t.undefined, t.string]),
  /**
   * Description of the collection
   */
  description: t.union([t.undefined, t.string]),
  /**
   * URL of the icon for this collection
   */
  icon_url: t.union([t.undefined, t.string]),
  /**
   * URL of the metadata for this collection
   * If metadata_api_url is modified AFTER minting, the existing NFTs that used the previous metadata_api_url will not be modified.
   * The new metadata_api_url will be used for new NFT mints.
   */
  metadata_api_url: t.union([t.undefined, t.string]),
  /**
   * URL of the tile image for this collection
   */
  collection_image_url: t.union([t.undefined, t.string]),
});
/** @deprecated */
export type UpdateCollectionParamsF = t.TypeOf<
  typeof UpdateCollectionParamsCodec
>;
export type UpdateCollectionParams = t.OutputOf<
  typeof UpdateCollectionParamsCodec
>;

/**
 * Add metadata schema to a collection
 *
 * @deprecated
 */
export enum MetadataTypes {
  Enum = 'enum',
  Text = 'text',
  Boolean = 'boolean',
  Continuous = 'continuous',
  Discrete = 'discrete',
}
/** @deprecated */
export const MetadataTypesT = fromEnum<MetadataTypes>(
  'MetadataTypes',
  MetadataTypes,
);

/** @deprecated */
export const MetadataObjectCodec = t.intersection([
  t.type({
    /**
     * Name of the metadata key (required)
     */
    name: t.string,
  }),
  t.partial({
    /**
     * Type of the metadata. (required) Values: "enum", "text", "boolean", "continuous", "discrete"
     * Src: https://docs.x.immutable.com/docs/asset-metadata#property-type-mapping
     * Defaults to 'text'
     */
    type: t.union([t.undefined, MetadataTypesT]),
    /**
     * Sets the metadata as filterable
     * Defaults to false
     */
    filterable: t.union([t.undefined, t.boolean]),
  }),
]);

/**
 * Request parameters for adding metadata schema to a collection
 *
 * @deprecated
 */
export const AddMetadataSchemaToCollectionParamsCodec = t.type({
  /**
   * The metadata container (required)
   */
  metadata: t.array(MetadataObjectCodec),
});
/** @deprecated */
export type AddMetadataSchemaToCollectionParamsF = t.TypeOf<
  typeof AddMetadataSchemaToCollectionParamsCodec
>;
export type AddMetadataSchemaToCollectionParams = t.OutputOf<
  typeof AddMetadataSchemaToCollectionParamsCodec
>;

/**
 * Request parameters for updating metadata schema by name
 *
 * @deprecated
 */
export const UpdateMetadataSchemaByNameParamsCodec = t.partial({
  /**
   * The new metadata key if different from existing key
   */
  name: t.union([t.undefined, t.string]),
  /**
   * Type of the metadata. (required) Values: "enum", "text", "boolean", "continuous", "discrete"
   * Src: https://docs.x.immutable.com/docs/asset-metadata#property-type-mapping
   * Defaults to 'text'
   */
  type: t.union([t.undefined, MetadataTypesT]),
  /**
   * Sets the metadata as filterable
   * Defaults to false
   */
  filterable: t.union([t.undefined, t.boolean]),
});
/** @deprecated */
export type UpdateMetadataSchemaByNameParamsF = t.TypeOf<
  typeof UpdateMetadataSchemaByNameParamsCodec
>;
export type UpdateMetadataSchemaByNameParams = t.OutputOf<
  typeof UpdateMetadataSchemaByNameParamsCodec
>;

/**
 * Result of creating a collection
 *
 * @deprecated
 */
export const CreateCollectionsResultsCodec = t.type({
  next: t.string,
  message: t.string,
});
/** @deprecated */
export type CreateCollectionsResultF = t.TypeOf<
  typeof CreateCollectionsResultsCodec
>;
export type CreateCollectionsResult = t.OutputOf<
  typeof CreateCollectionsResultsCodec
>;

/**
 * Result of updating a collection
 *
 * @deprecated
 */
export const UpdateCollectionsResultCodec = t.type({
  message: t.string,
});
/** @deprecated */
export type UpdateCollectionsResultF = t.TypeOf<
  typeof UpdateCollectionsResultCodec
>;
export type UpdateCollectionsResults = t.OutputOf<
  typeof UpdateCollectionsResultCodec
>;

/**
 * Result of adding metadata schema to a collection
 *
 * @deprecated
 */
export const AddMetadataSchemaToCollectionResultCodec = t.type({
  next: t.string,
  message: t.string,
});
/** @deprecated */
export type AddMetadataSchemaToCollectionResultF = t.TypeOf<
  typeof AddMetadataSchemaToCollectionResultCodec
>;
export type AddMetadataSchemaToCollectionResult = t.OutputOf<
  typeof AddMetadataSchemaToCollectionResultCodec
>;

/**
 * Result of updaating metadata schema by name
 *
 * @deprecated
 */
export const UpdateMetadataSchemaByNameResultCodec = t.type({
  message: t.string,
});
/** @deprecated */
export type UpdateMetadataSchemaByNameResultF = t.TypeOf<
  typeof UpdateMetadataSchemaByNameResultCodec
>;
export type UpdateMetadataSchemaByNameResult = t.OutputOf<
  typeof UpdateMetadataSchemaByNameResultCodec
>;
