import { ExternalProvider } from '@ethersproject/providers/lib/web3-provider';
import { EthNetworkName, Magic } from 'magic-sdk';

interface MagicConfig {
  publishableKey: string;
  network: EthNetworkName;
}

export const getMagicConfig = (): MagicConfig => {
  const storedNetwork =
    typeof window !== 'undefined'
      ? window.localStorage?.getItem('ethNetwork')
      : 'ropsten';

  return {
    publishableKey:
      storedNetwork === 'mainnet'
        ? 'pk_live_7539DBF48A93AC05'
        : 'pk_test_F50C312F987493C9',
    network: storedNetwork as EthNetworkName,
  };
};

export const magicClient = () => {
  const config = getMagicConfig();
  return new Magic(config.publishableKey, {
    network: config.network,
  });
};

export const createMagicProvider = async (
  magic = magicClient(),
): Promise<ExternalProvider> =>
  new Promise((resolve, reject) => {
    magic.user
      .isLoggedIn()
      .then(res => {
        res
          ? resolve(magic.rpcProvider)
          : reject(new Error('Magic User is not authenticated'));
      })
      .catch(reject);
  });
