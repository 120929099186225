import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as https from 'https';

/**
 * Client for making HTTP requests using TypeScript only
 */
export class RequestClient {
  private baseUrl: string;

  private agent: https.Agent;

  private baseOptions: AxiosRequestConfig;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.agent = new https.Agent();

    this.baseOptions = {
      baseURL: this.baseUrl,
      httpsAgent: this.agent,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  /**
   * Request configuration
   */
  public replaceApiVersion(path: string, version: string): string {
    return path.replace(/\/v[0-9]+$/, `/${version}`);
  }

  public addHeaders(
    options: AxiosRequestConfig,
    headers: { [key: string]: string },
  ): AxiosRequestConfig {
    return {
      ...options,
      headers: {
        ...options.headers,
        ...headers,
      },
    };
  }

  /**
   * Response configuration
   */
  private handleResponse<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  private handleErrors(error: AxiosError): any {
    if (error.response?.data) {
      throw error.response?.data;
    }
    throw error;
  }

  /**
   * HTTP methods
   */
  public async get(
    path: string,
    headers?: { [key: string]: string },
    version?: string,
  ): Promise<unknown> {
    let options = this.baseOptions;

    if (version && options.baseURL) {
      options.baseURL = this.replaceApiVersion(options.baseURL, version);
    }

    if (headers) {
      options = this.addHeaders(options, headers);
    }

    try {
      const response = await axios.get(`${path}`, options);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrors(error as AxiosError<unknown>);
    }
  }

  public async post(
    path: string,
    data: any,
    headers?: { [key: string]: string },
    version?: string,
  ): Promise<unknown> {
    let options = this.baseOptions;

    if (version && options.baseURL) {
      options.baseURL = this.replaceApiVersion(options.baseURL, version);
    }

    if (headers) {
      options = this.addHeaders(options, headers);
    }

    try {
      const response = await axios.post(`${path}`, data, options);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrors(error as AxiosError<unknown>);
    }
  }

  public async patch(
    path: string,
    data: any,
    headers?: { [key: string]: string },
    version?: string,
  ): Promise<unknown> {
    let options = this.baseOptions;

    if (version && options.baseURL) {
      options.baseURL = this.replaceApiVersion(options.baseURL, version);
    }

    if (headers) {
      options = this.addHeaders(options, headers);
    }

    try {
      const response = await axios.patch(`${path}`, data, options);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrors(error as AxiosError<unknown>);
    }
  }

  public async delete(path: string, data: any): Promise<unknown> {
    const options = {
      ...this.baseOptions,
      data,
    };

    try {
      const response = await axios.delete(`${path}`, options);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrors(error as AxiosError<unknown>);
    }
  }
}
