import qs from 'qs';
import queryString from 'query-string';

import { LinkParams, LinkResults } from './types';
import {
  ConfigurableIframeOptions,
  FullIframeOptions,
  LINK_MESSAGE_TYPE,
  messageTypes,
  Routes,
} from './types/sdk';
import { experimental } from './utils/experimental';
import { STRINGIFY_SETTINGS } from './utils/qs-settings';

export const getFullIframeOptions = (): FullIframeOptions => ({
  containerElement: document.body,
  className: '',
  size: {
    width: 375,
    height: 667,
  },
  position: {
    right: '0%',
    bottom: '0%',
  },
  protectAgainstGlobalStyleBleed: true,
});

const xButtonSize = '22px';
const globalStyleBleedProtection = `
  /*
  HARDEN STYLES TO PREVENT SOME GLOBAL STYLES FROM AFFECTING THEM
  ----------------------------------------------------------------
  This should solve issues that token trove are encountering.
  */
  .imxLinkIframeContainer__closeButton {
      transition: none !important;
      width: ${xButtonSize} !important;
      height: ${xButtonSize} !important;
      min-width: ${xButtonSize} !important;
      min-height: ${xButtonSize} !important;
      margin: 0 !important;
      border: none !important;
  }
`;

const renderEmbedStyles = ({
  size,
  position,
  protectAgainstGlobalStyleBleed,
}: FullIframeOptions) => `
  .imxLinkIframeContainer {
      position: fixed;
      ${position.top ? `top: ${position.top};` : ''}
      ${position.bottom ? `bottom: ${position.bottom};` : ''}
      ${position.left ? `left: ${position.left};` : ''}
      ${position.right ? `right: ${position.right};` : ''}
      width: ${size.width}px;
      height: ${size.height}px;
  }
  .imxLinkIframeContainer__iframe {
      display: block;
      height: 100%;
      width: 100%;
      border: none;
  }

  .imxLinkIframeContainer__closeButton {
      width: ${xButtonSize};
      height: ${xButtonSize};
      min-width: ${xButtonSize};
      min-height: ${xButtonSize};
      opacity: 0.4;
      position: absolute;
      z-index: 1;
      top: 15px;
      right: 15px;
      background: transparent url(https://images.godsunchained.com/misc/white-menu-close.svg) center;
      border: none;
      padding: 0;
      cursor: pointer;
  }

  ${protectAgainstGlobalStyleBleed ? globalStyleBleedProtection : ''}

  .imxLinkIframeContainer__closeButton:hover {
      opacity: 1;
  }
`;

export class Link {
  constructor(
    private webUrl = 'https://link.dev.x.immutable.com',
    private iframeOptions: ConfigurableIframeOptions = null,
  ) {}

  private buildUrl(route: Routes, params: any, legacy: boolean) {
    return legacy
      ? queryString.stringifyUrl({
          url: `${this.webUrl}/${route}`,
          query: params || {},
        })
      : `${this.webUrl}/${route}${qs.stringify(params, STRINGIFY_SETTINGS)}`;
  }

  private openIframeOrWindow =
    <I, O = void>(route: Routes) =>
    (params: I): Promise<O> =>
      this.iframeOptions
        ? this.openIframe(route, params)
        : this.openWindow(route, params);

  private openIframe = <I, O = void>(route: Routes, params: I): Promise<O> =>
    new Promise((resolve, reject) => {
      const url = this.buildUrl(route, params, route !== Routes.TransferV2);
      const unloadIframe = () => {
        window.removeEventListener('message', eventListener, false);
        fullIframeOptions.containerElement.removeChild(containerDom);
        reject(new Error('Link iFrame Closed'));
      };

      const fullIframeOptions: FullIframeOptions = {
        ...getFullIframeOptions(),
        ...this.iframeOptions,
      };
      const styleSheet = document.createElement('style');
      const containerDom = document.createElement('div');
      const iframeDom = document.createElement('iframe');
      const closeButtonDom = document.createElement('button');
      containerDom.classList.add('imxLinkIframeContainer');
      fullIframeOptions.className &&
        containerDom.classList.add(fullIframeOptions.className);
      iframeDom.classList.add('imxLinkIframeContainer__iframe');
      iframeDom.setAttribute(
        'sandbox',
        'allow-same-origin allow-scripts allow-forms allow-popups',
      );
      closeButtonDom.classList.add('imxLinkIframeContainer__closeButton');
      styleSheet.append(
        document.createTextNode(renderEmbedStyles(fullIframeOptions)),
      );
      containerDom.append(styleSheet, iframeDom, closeButtonDom);
      closeButtonDom.addEventListener('click', unloadIframe);

      // @NOTE: set the source of the iframe, and finally inject the iframe
      // container into the document:
      iframeDom.setAttribute('src', url);
      fullIframeOptions.containerElement.appendChild(containerDom);

      const eventListener = (event: MessageEvent) => {
        if (event.origin !== this.webUrl) return;
        const { data } = event;

        if (data.type === LINK_MESSAGE_TYPE) {
          switch (data.message) {
            case messageTypes.inProgress: {
              console.log(`${route} In Progress`);
              break;
            }
            case messageTypes.success: {
              console.log(`${route} Succeeded`, data.data);
              resolve((data.data ?? {}) as O);
              break;
            }
            case messageTypes.fail: {
              console.log(`${route} Failed`);
              reject();
              break;
            }
            // This is only used by setup function
            // TODO see if we can consolidate result and success
            case messageTypes.result: {
              console.log(`${route} Succeeded with result`, data.data);
              resolve((data.data ?? {}) as O);
              unloadIframe();
              break;
            }
            case messageTypes.close: {
              unloadIframe();
              break;
            }
            default: {
              console.error('Unknown message', data);
              break;
            }
          }
        }
      };

      window.addEventListener('message', eventListener, false);
    });

  private openWindow = <I, O = void>(route: Routes, params: I): Promise<O> =>
    new Promise((resolve, reject) => {
      const defaultOptions = getFullIframeOptions();
      const url = this.buildUrl(route, params, route !== Routes.TransferV2);
      const win = window.open(
        url,
        'imx-link',
        `menubar=yes,location=no,resizable=no,scrollbars=no,status=yes,width=${defaultOptions.size.width},height=${defaultOptions.size.height}`,
      );
      if (!win) {
        throw new Error('Unable to open window');
      }

      const checkClosed = setInterval(() => {
        if (win.closed) {
          clearInterval(checkClosed);
          reject(new Error('Link Window Closed'));
        }
      }, 500);

      const eventListener = (event: MessageEvent) => {
        if (event.origin !== this.webUrl) return;
        const { data } = event;

        if (data.type === LINK_MESSAGE_TYPE) {
          switch (data.message) {
            case messageTypes.inProgress: {
              console.log(`${route} In Progress`);
              break;
            }
            case messageTypes.success: {
              console.log(`${route} Succeeded`, data.data);
              resolve((data.data ?? {}) as O);
              break;
            }

            case messageTypes.fail: {
              console.log(`${route} Failed`);
              reject();
              break;
            }

            case messageTypes.result: {
              console.log(`${route} Succeeded with result`, data.data);
              window.removeEventListener('message', eventListener, false);
              clearInterval(checkClosed);
              win.close();
              resolve((data.data ?? {}) as O);
              break;
            }

            case messageTypes.close: {
              window.removeEventListener('message', eventListener, false);
              clearInterval(checkClosed);
              win.close();
              reject(new Error('Link Window Closed'));
              break;
            }

            default: {
              console.error('Unknown message', data);
              break;
            }
          }
        }
      };

      window.addEventListener('message', eventListener, false);
    });

  history = this.openIframeOrWindow<LinkParams.History>(Routes.History);

  setup = this.openIframeOrWindow<LinkParams.Setup, LinkResults.Setup>(
    Routes.Setup,
  );

  buy = this.openIframeOrWindow<LinkParams.BuyV2, LinkResults.BuyV2>(
    Routes.BuyV2,
  );

  /**
   * NOTE: optional parameters amount and currencyAddress are experimental and not supported in production environment
   * @experimentalParams
   *
   * @param params
   */
  sell = async (params: LinkParams.Sell) => {
    const callback = () =>
      this.openIframeOrWindow<LinkParams.Sell>(Routes.Sell)(params);

    await experimental(callback, {
      runCondition: !params.amount || !!params.currencyAddress,
      checkReferrer: true,
      message:
        'Using link.sell() without amount or with currencyAddress is an experimental feature, it should not be used in production.',
    });
  };

  deposit = this.openIframeOrWindow<LinkParams.Deposit>(Routes.Deposit);

  prepareWithdrawal = this.openIframeOrWindow<
    LinkParams.PrepareWithdrawal,
    LinkResults.PrepareWithdrawal
  >(Routes.PrepareWithdrawal);

  completeWithdrawal = this.openIframeOrWindow<
    LinkParams.CompleteWithdrawal,
    LinkResults.CompleteWithdrawal
  >(Routes.CompleteWithdrawal);

  transfer = this.openIframeOrWindow<
    LinkParams.TransferV2,
    LinkResults.TransferV2
  >(Routes.TransferV2);

  cancel = this.openIframeOrWindow<LinkParams.Cancel>(Routes.Cancel);

  claim = async () => {
    console.warn(
      "Warning: 'Link.claim()' is only for the 'IMX' reward campaign.",
    );

    const openClaim = await this.openIframeOrWindow<LinkParams.Claim>(
      Routes.Claim,
    );

    return openClaim({});
  };

  /**
   * NOTE: link.exchange() is an experimental feature, it should not be used in production.
   * @experimentalParams
   *
   * @param params
   */
  exchange = async (params: LinkParams.Exchange) => {
    const callback = () =>
      this.openIframeOrWindow<LinkParams.Exchange, LinkResults.Setup>(
        Routes.Exchange,
      )(params);
    await experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'link.exchange() is an experimental feature, it should not be used in production.',
    });
  };
}
