const whitelistedDomain = ['immutable.com', 'localhost'];

export interface ExperimentalOptions {
  runCondition: boolean;
  checkReferrer: boolean;
  message: string;
}

export const experimental = async (
  callback: () => Promise<any>,
  options: ExperimentalOptions,
): Promise<any> => {
  if (options.runCondition) {
    console.warn(options.message);

    if (options.checkReferrer && !isWhitelisted()) {
      console.error('You are not whitelisted for experimental features');

      return Promise.resolve();
    }
  }

  return callback();
};

const isWhitelisted = (): boolean => {
  return !!whitelistedDomain.find(domain =>
    window.location.origin.includes(domain),
  );
};
